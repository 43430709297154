import { SettingsObject } from '.';
import {
    been,
    befr,
    benl,
    caen,
    cafr,
    czcs,
    czen,
    envDefaults,
    esca,
    esen,
    eses,
    eseu,
    esgl,
    frfr,
    gben,
    huhu,
    ieen,
    inen,
    jpen,
    jpja,
    kren,
    krko,
    roro,
    rssr,
    sksk,
    usen,
} from '../../environments';
import { MarketName } from 'lib/global';

// Adding default layer to the market settingObject since this is how ES6 modules handle default exports
const markets: Record<string, { default: Partial<SettingsObject> }> = {
    been,
    befr,
    benl,
    caen,
    cafr,
    czcs,
    czen,
    esca,
    esen,
    eses,
    eseu,
    esgl,
    frfr,
    gben,
    huhu,
    ieen,
    inen,
    jpen,
    jpja,
    kren,
    krko,
    roro,
    rssr,
    sksk,
    usen,
};

export const getFiles = (market: MarketName) => {
    const defaults = envDefaults(market);
    if (market in markets) {
        return {
            ...defaults,
            ...markets[market].default,
        } as SettingsObject;
    }
    return defaults as SettingsObject;
};
