import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { get as getPostalCode } from 'utils/window/postalCode';
import { subscribe, unsubscribe } from 'utils/window/pubsub';
import {
    clear as clearStore,
    get as getStore,
    set as setStore,
} from 'utils/window/store';

interface PreferredPostalCode {
    postalCode: string;
    source: string;
}

type UserPreferences = {
    setPreferredStore: (id: string | null) => void;
    preferredStore?: string;
    preferredPostalCode?: PreferredPostalCode | null;
};

const UserPreferencesContext = createContext<UserPreferences>({
    setPreferredStore: () => undefined,
});

export const UserPreferencesProvider = ({ children }: PropsWithChildren) => {
    const [storeQueryId, setStoreQueryId] = useState<string | null>(null);
    const [postalCodeQueryId, setPostalCodeQueryId] = useState<string | null>(
        null
    );
    const { data: preferredStore } = useQuery(
        ['preferredStore', storeQueryId], // Ensures query will automatically refetch when store changes.
        () => getStore(),
        {
            onError: err => captureException(err),
            keepPreviousData: true,
        }
    );

    const { data: preferredPostalCode } = useQuery(
        ['preferredPostalCode', postalCodeQueryId], // Ensures query will automatically refetch when store changes.
        () => getPostalCode(),
        {
            onError: err => captureException(err),
            keepPreviousData: true,
        }
    );

    // When user sets a new preferred store location, update our state to reflect it.
    // (The local storage is updated by the nav team so no need to set it)
    useEffect(() => {
        const onStoreChange = (id: string) => {
            setStoreQueryId(id);
        };
        subscribe<string>('NAVIGATION/STORE_SET', onStoreChange);
        return () => {
            unsubscribe('NAVIGATION/STORE_SET', onStoreChange as () => void);
        };
    }, []);

    // When user sets a new preferred postal code, update our state to reflect it.
    // (The local storage is updated by the nav team so no need to set it)
    useEffect(() => {
        const onPostalCodeChange = (id: string) => {
            setPostalCodeQueryId(id);
        };
        subscribe<string>('NAVIGATION/POSTALCODE_SET', onPostalCodeChange);
        return () => {
            unsubscribe(
                'NAVIGATION/POSTALCODE_SET',
                onPostalCodeChange as () => void
            );
        };
    }, []);

    const value = useMemo(
        () => ({
            setPreferredStore: (id: string | null) => {
                if (!id) {
                    clearStore();
                } else {
                    setStore(id);
                }
            },
            preferredPostalCode,
            preferredStore,
        }),
        [preferredStore, preferredPostalCode]
    );
    return (
        <UserPreferencesContext.Provider value={value}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

export const useUserPreferences = () => useContext(UserPreferencesContext);
